import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Función para convertir una cantidad en palabras
function numberToWords(num) {
  const units = ['', 'UN', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
  const teens = ['DIEZ', 'ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISÉIS', 'DIECISIETE', 'DIECIOCHO', 'DIECINUEVE'];
  const tens = ['', 'DIEZ', 'VEINTE', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];
  const hundreds = ['', 'CIENTO', 'DOSCIENTOS', 'TRESCIENTOS', 'CUATROCIENTOS', 'QUINIENTOS', 'SEISCIENTOS', 'SETECIENTOS', 'OCHOCIENTOS', 'NOVECIENTOS'];
  const thousands = ['MIL', 'MILLÓN', 'MIL MILLONES', 'BILLÓN', 'MIL BILLONES'];

  function convertToWords(num, index) {
    if (num === 0) return '';
    if (num < 10) return units[num];
    if (num < 20) return teens[num - 10];
    if (num < 100) return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
    if (num < 1000) return hundreds[Math.floor(num / 100)] + ' ' + convertToWords(num % 100, 0);
    return convertToWords(Math.floor(num / 1000), index + 1) + ' ' + thousands[index] + ' ' + convertToWords(num % 1000, 0);
  }

  if (num === 0) return 'CERO';
  const numStr = num.toString();
  const numParts = numStr.split('.');
  const integerPart = parseInt(numParts[0]);
  const decimalPart = parseInt(numParts[1] || '0');

  let words = convertToWords(integerPart, 0);

  if (decimalPart > 0) {
    words += ' PESOS CON ' + convertToWords(decimalPart, 0) + ' CENTAVOS';
  } else{
    words += ' PESOS';
  }

  return words;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'transparent' ,
  },
  section: {
    margin: 5,
    padding: 10,
    flexGrow: 1,
    backgroundColor:'transparent',
  },
  title: {
    fontSize: 13,
    textAlign: 'center',
    backgroundColor: '#000000',
    color: '#FFFFFF', 
    padding: 5,
    fontWeight: 'bold',
  },
  header: {
    border: '1px solid #000000', 
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'center',
  },
  subtitle1: {
    paddingTop:'10px',
    fontSize: 12,
    textAlign: 'center',
    paddingRight:'20px',
  },
  subtitle: {
    paddingTop:'3px',
    paddingBottom:'3px',
    paddingLeft:'10%',
    fontSize: 12,
    textAlign: 'center',
  },
  contenti: {
    fontSize: 10,
    textAlign: 'left',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  firma: {
    marginTop: 20,
    fontSize: 10,
    textAlign: 'center'
  },
  cell: {
    border: '1px solid #000000', 
  },
  images: {
    height: '30px',
    width: '100px',
    paddingLeft:'20px'
  },
  inlineStyle:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
  },
  backgroundImage: {
    paddingTop:'10%',
    paddingLeft:'18%',
    paddingRight:'18%',
    position: 'absolute',
    width: '100%',
    height: '80%',
    zIndex: -1,
    opacity: 0.9,
  },
  line: {
    paddingTop:'3px',
    flex: 1,
    borderBottom: '1px solid #000',
    margin: '0 -5px', 
    width:'388px'
  },
  line1: {
    paddingTop:'3px',
    flex: 1,
    borderBottom: '1px solid #000',
    margin: '0 -1px', 
    width:'389px'
  },
  ovalContainer: {
    width: '90px',
    height: '25px',
    borderRadius: '45px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10%',
    borderColor:'black',
    borderWidth: '1px', 
  },
  ovalText: {
    fontSize: 10,
  },
});

const MyDocument = (props) => {
  const dateObject = new Date(props.datos.fecha);
  const formattedDate = dateObject.toLocaleDateString();
  const totalInWords = numberToWords(props.datos.total)
  return (

  <Document>
    <Page size="A6" orientation="landscape" style={styles.page}>
    <Image
        src={require('../../Images/corazon.png')} // Cambia la ruta de la imagen de fondo
        style={styles.backgroundImage}
      />
      <View style={styles.section}>
        <Text style={styles.title}>CONTRARECIBO POR PRESTACIÓN DE SERVICIOS</Text>
        <View style={styles.header}>
          <Image src={require('../../Images/DIF.jpg')} style={styles.images} />
          <View style={styles.ovalContainer}>
            <Text style={styles.ovalText}>{props.datos.folio}</Text>
          </View>
        </View>
        <View style={styles.cell}>
          <View style={styles.inlineStyle}> 
            <Text style={[styles.subtitle, { flex: 1, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }]}>
              {props.datos.dependencia}
            </Text>
          </View>
        </View>
        <View style={styles.cell}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between',paddingTop:'3px'  }}>
            <View style={[{ flex: 4 }, styles.contenti]}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>FECHA:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{formattedDate}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>NOMBRE DEL USUARIO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{props.datos.cliente}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>CONCEPTO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{props.datos.conceptos}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE TOTAL:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.total}.00</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>CANTIDAD CON LETRA:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{totalInWords} 00/100 MXN</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE ABONADO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.abono}.00</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE RESTANTE:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.adeudo}.00</Text>
              </View>
            </View>
          </View>
          <View style={styles.line1} /> {/* Línea horizontal */}
          <View style={styles.firma}>
          <Text>______________________</Text>
          <Text>Firma de quien recibe    </Text>
        </View>
        </View>
      </View>
    </Page>

    <Page size="A6" orientation="landscape" style={styles.page}>
    <Image
        src={require('../../Images/corazon.png')} // Cambia la ruta de la imagen de fondo
        style={styles.backgroundImage}
      />
      <View style={styles.section}>
        <Text style={styles.title}>CONTRARECIBO POR PRESTACIÓN DE SERVICIOS</Text>
        <View style={styles.header}>
          <Image src={require('../../Images/DIF.jpg')} style={styles.images} />
          <View style={styles.ovalContainer}>
            <Text style={styles.ovalText}>{props.datos.folio}</Text>
          </View>
        </View>
        <View style={styles.cell}>
          <View style={styles.inlineStyle}> 
            <Text style={[styles.subtitle, { flex: 1, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }]}>
              {props.datos.dependencia}
            </Text>
          </View>
        </View>
        <View style={styles.cell}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between',paddingTop:'3px'  }}>
            <View style={[{ flex: 4 }, styles.contenti]}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>FECHA:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{formattedDate}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>NOMBRE DEL USUARIO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{props.datos.cliente}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>CONCEPTO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{props.datos.conceptos}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE TOTAL:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.total}.00</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>CANTIDAD CON LETRA:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{totalInWords} 00/100 MXN</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE ABONADO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.abono}.00</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE RESTANTE:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.adeudo}.00</Text>
              </View>
            </View>
          </View>
          <View style={styles.line1} /> {/* Línea horizontal */}
          <View style={styles.firma}>
          <Text>______________________</Text>
          <Text>Firma de quien recibe    </Text>
        </View>
        </View>
      </View>
    </Page>

    <Page size="A6" orientation="landscape" style={styles.page}>
    <Image
        src={require('../../Images/corazon.png')} // Cambia la ruta de la imagen de fondo
        style={styles.backgroundImage}
      />
      <View style={styles.section}>
        <Text style={styles.title}>CONTRARECIBO POR PRESTACIÓN DE SERVICIOS</Text>
        <View style={styles.header}>
          <Image src={require('../../Images/DIF.jpg')} style={styles.images} />
          <View style={styles.ovalContainer}>
            <Text style={styles.ovalText}>{props.datos.folio}</Text>
          </View>
        </View>
        <View style={styles.cell}>
          <View style={styles.inlineStyle}> 
            <Text style={[styles.subtitle, { flex: 1, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }]}>
              {props.datos.dependencia}
            </Text>
          </View>
        </View>
        <View style={styles.cell}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between',paddingTop:'3px'  }}>
            <View style={[{ flex: 4 }, styles.contenti]}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>FECHA:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{formattedDate}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>NOMBRE DEL USUARIO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{props.datos.cliente}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>CONCEPTO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{props.datos.conceptos}</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE TOTAL:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.total}.00</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>CANTIDAD CON LETRA:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>{totalInWords} 00/100 MXN</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE ABONADO:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.abono}.00</Text>
              </View>
              <View style={styles.line} /> {/* Línea horizontal */}
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingTop: '3px', alignItems: 'center' }}>
                <Text style={{ width: '40%', paddingRight: '5px', fontWeight: 'bold' }}>IMPORTE RESTANTE:</Text>
                <Text style={{ width: '60%', paddingLeft: '5px', textAlign: 'right' }}>$ {props.datos.adeudo}.00</Text>
              </View>
            </View>
          </View>
          <View style={styles.line1} /> {/* Línea horizontal */}
          <View style={styles.firma}>
          <Text>______________________</Text>
          <Text>Firma de quien recibe    </Text>
        </View>
        </View>
      </View>
    </Page>
  </Document>
);};


export default MyDocument;
